import React from 'react'
import axios from 'axios'

import Layout from '../components/layout'
import SEO from '../components/SEO'
import Footer from '../components/footer'
import PageWrapper from '../components/pageWrapper'
import StandardPadding from '../components/standardPadding'
import FormStyler from '../components/formStyler'

import RedButton from '../components/redButton'

import Reveal from 'react-reveal/Reveal'
import styles from '../styles/pages/partnership-application.module.scss'

import southFinch from '../images/finches/5Finch-drawing-south.png'
import quoteBox from '../images/quote-box.png'

import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

const GOOGLE_FORM_ACTION_URL =
  'https://docs.google.com/forms/u/0/d/e/1FAIpQLSfFbOe-54xwKyE4a8BcmTnKlram01m2wSqeGswXaxSXhh__Yw/formResponse'
const GOOGLE_FORM_COMPANY_NAME_ID = 'entry.863317727'
const GOOGLE_FORM_COMPANY_URL_ID = 'entry.2016547176'
const GOOGLE_FORM_VIDEO_LINK_ID = 'entry.23289104'
const GOOGLE_FORM_ELEVATOR_PITCH_ID = 'entry.1457566402'
const GOOGLE_FORM_DESCRIPTION_ID = 'entry.2069666648'
const GOOGLE_FORM_GOALS_ID = 'entry.54327280'
const GOOGLE_FORM_SERVICES_SEEKING_ID = 'entry.698135099'
const GOOGLE_FORM_VALUATION_ID = 'entry.1919839465'
const GOOGLE_FORM_NAME_ID = 'entry.344901089'
const GOOGLE_FORM_EMAIL_ID = 'entry.1667758138'

const CORS_PROXY = 'https://wbcg-cors-anywhere.herokuapp.com/'

const FormErrors = ({ formErrors }) => {
  console.log('FORM_ERRORS: ', formErrors)
  return (
    <div className={styles.formErrors}>
      {Object.keys(formErrors).map((fieldName, i) => {
        if (formErrors[fieldName].length > 0) {
          return (
            <p key={i}>
              {fieldName} {formErrors[fieldName]}
            </p>
          )
        } else {
          return ''
        }
      })}
    </div>
  )
}

class AcceleratorApplication extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      companyName: '',
      companyUrl: '',
      videoLink: '',
      elevatorPitch: '',
      description: '',
      goals: '',
      servicesSeeking: '',
      valuation: '',
      name: '',
      email: '',
      showForm: true,
      savedName: '',
      formErrors: { email: '', url: '' },
      emailValid: true,
      urlValid: true,
      formValid: false,
      showErrors: false,
      sendingMessage: false,
      messageSent: false,
      messageError: false,
      isMobile: null,
    }
    this.handleFormToggle = this.handleFormToggle.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleFormToggle = () => {
    this.setState(prevState => {
      const { showForm } = prevState
      return {
        showForm: !showForm,
      }
    })
  }

  validateField = (fieldName, value) => {
    let fieldValidationErrors = this.state.formErrors
    let emailValid = this.state.emailValid
    let urlValid = this.state.urlValid
    let urlValidMatch

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        fieldValidationErrors.email = emailValid ? '' : ' is invalid'
        break
      case 'companyUrl':
        urlValidMatch = value.match(
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
        )
        urlValid = urlValidMatch || value === '' // allow url to be left blank if started typing but removed input
        fieldValidationErrors.url = urlValid ? '' : 'is invalid'

        break
      default:
        break
    }

    //console.log('FIELD_VALIDATION_ERRORS: ', fieldValidationErrors)

    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        urlValid: urlValid,
      },
      this.validateForm
    )
  }

  validateForm() {
    this.setState({
      formValid: this.state.emailValid && this.state.urlValid,
    })
  }

  handleChange = event => {
    //console.log('event name: ', event.target.name)
    const name = event.target.name
    const value = event.target.value
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({
      sendingMessage: true,
    })
    if (this.state.formValid) {
      this.sendMessage()
      // this.handleFormToggle()
    } else {
      this.setState({ showErrors: true })
    }
  }

  sendMessage = () => {
    const formData = new FormData()
    formData.append(GOOGLE_FORM_COMPANY_NAME_ID, this.state.companyName)
    formData.append(GOOGLE_FORM_COMPANY_URL_ID, this.state.companyUrl)
    formData.append(GOOGLE_FORM_VIDEO_LINK_ID, this.state.videoLink)
    formData.append(GOOGLE_FORM_ELEVATOR_PITCH_ID, this.state.elevatorPitch)
    formData.append(GOOGLE_FORM_DESCRIPTION_ID, this.state.description)
    formData.append(GOOGLE_FORM_GOALS_ID, this.state.goals)
    formData.append(GOOGLE_FORM_SERVICES_SEEKING_ID, this.state.servicesSeeking)
    formData.append(GOOGLE_FORM_VALUATION_ID, this.state.valuation)
    formData.append(GOOGLE_FORM_NAME_ID, this.state.name)
    formData.append(GOOGLE_FORM_EMAIL_ID, this.state.email)

    axios
      .post(CORS_PROXY + GOOGLE_FORM_ACTION_URL, formData)
      .then(() => {
        this.setState({
          savedName: this.state.name,
          companyName: '',
          companyUrl: '',
          videoLink: '',
          elevatorPitch: '',
          description: '',
          goals: '',
          servicesSeeking: '',
          valuation: '',
          name: '',
          email: '',
          messageSent: true,
          sendingMessage: false,
        })
        this.handleFormToggle()
      })
      .catch(() => {
        //console.log('FORM BACKEND ERROR')
        this.setState({ messageError: true, sendingMessage: false })
      })
  }

  render() {
    //console.log('FORM_ERRORS_N_RENDER: ', this.state.formErrors)
    return (
      <Layout>
        <SEO
          customTitle="15 Finches Partnership Application"
          pagePath="partnership-application"
        />
        <PageWrapper>
          <StandardPadding>
            <section className={styles.hero}>
              <p className={styles.label}>15 Finches Partnership Program</p>
              <h1>Application Form</h1>
              <p className={styles.subtitle}>
                Offering the best early-stage startups access to mentorship,
                transformational workshops and an array of creative, development
                and product services during the three month program. Accepted
                startups will receive a unique program schedule as well as
                networking and pitch opportunities with founders and VCs. Apply
                here and if accepted, we'll reach out to you with a program
                curated for your startup's needs. You'll receive a program
                schedule, list of services provided and a proposed amount of
                equity and cash for program fees.
              </p>
            </section>
            {!this.state.showForm && (
              <div className={styles.responseGroup}>
                <div className={styles.finchIcon}>
                  <img src={southFinch} alt="finch head" />
                </div>
                <div className={styles.quoteBox}>
                  <img src={quoteBox} alt="" />
                </div>
                <div className={styles.responseMessage}>
                  Hi {this.state.savedName}, <br />
                  Thanks for your note. We’re excited about the idea of building
                  something together! Expect a response from us soon.
                </div>
              </div>
            )}
            {this.state.showForm && (
              <FormStyler>
                <form onSubmit={this.handleSubmit}>
                  <Reveal effect="fadeIn" duration={1500}>
                    <div className={styles.group}>
                      <input
                        data-hover="expand"
                        type="text"
                        name="companyName"
                        value={this.state.companyName}
                        required
                        onChange={this.handleChange}
                      />
                      <label>Company Name</label>
                    </div>
                  </Reveal>
                  <Reveal effect="fadeIn" duration={1500}>
                    <div
                      className={cx('group', { error: !this.state.urlValid })}
                    >
                      <input
                        data-hover="expand"
                        type="text"
                        name="companyUrl"
                        value={this.state.companyUrl}
                        onChange={this.handleChange}
                      />
                      <label>Company url, if any</label>
                    </div>
                  </Reveal>
                  <Reveal effect="fadeIn" duration={1500}>
                    <div className={styles.group}>
                      <input
                        data-hover="expand"
                        type="text"
                        name="videoLink"
                        value={this.state.videoLink}
                        onChange={this.handleChange}
                      />
                      <label>Demo or video link</label>
                    </div>
                  </Reveal>
                  <Reveal effect="fadeIn" duration={1500}>
                    <div className={styles.group}>
                      <input
                        data-hover="expand"
                        type="text"
                        name="elevatorPitch"
                        value={this.state.elevatorPitch}
                        required
                        onChange={this.handleChange}
                      />
                      <label>Elevator pitch (100 words or less)</label>
                    </div>
                  </Reveal>
                  <Reveal effect="fadeIn" duration={1500}>
                    <div className={styles.group}>
                      <textarea
                        data-hover="expand"
                        type="text"
                        name="description"
                        value={this.state.description}
                        required
                        onChange={this.handleChange}
                      />
                      <label>
                        Description / What is your company building?
                      </label>
                    </div>
                  </Reveal>
                  <Reveal effect="fadeIn" duration={1500}>
                    <div className={styles.group}>
                      <input
                        data-hover="expand"
                        type="text"
                        name="goals"
                        value={this.state.goals}
                        required
                        onChange={this.handleChange}
                      />
                      <label>Short term and Long Term Goals</label>
                    </div>
                  </Reveal>
                  <Reveal effect="fadeIn" duration={1500}>
                    <div className={styles.group}>
                      <input
                        data-hover="expand"
                        type="text"
                        name="servicesSeeking"
                        value={this.state.servicesSeeking}
                        required
                        onChange={this.handleChange}
                      />
                      <label>Services and Guidance You're Seeking</label>
                    </div>
                  </Reveal>
                  <Reveal effect="fadeIn" duration={1500}>
                    <div className={styles.group}>
                      <input
                        data-hover="expand"
                        type="text"
                        name="valuation"
                        value={this.state.valuation}
                        required
                        onChange={this.handleChange}
                      />
                      <label>Current Valuation / Investment History</label>
                    </div>
                  </Reveal>
                  <Reveal effect="fadeIn" duration={1500}>
                    <div className={styles.group}>
                      <input
                        data-hover="expand"
                        type="text"
                        name="name"
                        value={this.state.name}
                        required
                        onChange={this.handleChange}
                      />
                      <label>Your Name</label>
                    </div>
                  </Reveal>
                  <Reveal effect="fadeIn" duration={1500}>
                    <div
                      className={cx('group', { error: !this.state.emailValid })}
                    >
                      <input
                        data-hover="expand"
                        type="text"
                        name="email"
                        value={this.state.email}
                        required
                        onChange={this.handleChange}
                      />
                      <label>Your Email</label>
                    </div>
                  </Reveal>
                  {this.state.showErrors && (
                    <FormErrors formErrors={this.state.formErrors} />
                  )}
                  <Reveal effect="fadeInUpShorter" duration={1000}>
                    <RedButton
                      disabled={!this.state.formValid}
                      text="Apply Now"
                      type="submit"
                      style={{ marginTop: '36px' }}
                    />
                  </Reveal>
                </form>
              </FormStyler>
            )}
          </StandardPadding>
        </PageWrapper>
        <Footer isFullHeight={false} />
      </Layout>
    )
  }
}

export default AcceleratorApplication
